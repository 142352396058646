import React from 'react'
import { Tooltip } from 'antd'
import { groupBy, sortBy, isEqual } from 'lodash'
import { STATUSES } from '../../entities/choices/status'
import { Task } from '../../entities/task/model'

function _TaskStatsBar(props: { tasks: Task[] }) {
  const statusGroups = groupBy(sortBy(props.tasks, 'status'), 'status')
  const activeStatuses = STATUSES.filter(
    (status) => !!statusGroups[status.value]
  )

  return !!activeStatuses.length ? (
    <div className="epic-stats-bar">
      {activeStatuses.map((status) => {
        return statusGroups[status.value].map((task) => {
          return (
            <Tooltip
              title={`${task.code} - ${task.name} - ${status.label}`}
              key={task.id}
            >
              <span
                className="epic-stats-bar__step"
                style={{ background: status.color }}
              />
            </Tooltip>
          )
        })
      })}
    </div>
  ) : null
}

export const TaskStatsBar = React.memo(_TaskStatsBar, isEqual)
