import { message } from 'antd'
import _ from 'lodash'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd'
import { isEqual, sortBy } from 'lodash'
import { Icon } from '@blueprintjs/core'
import classNames from 'classnames'
import { IconNames } from '@blueprintjs/icons'
import { SmallDashOutlined } from '@ant-design/icons'
import { useInViewport } from '@umijs/hooks'
import Skeleton from 'react-loading-skeleton'

import { Task } from '../../entities/task/model'
import { Epic } from '../../entities/epic/model'
import { SIZES } from '../../entities/choices/size'
import { Sprint, SprintStatus } from '../../entities/sprint/model'
import { STATUSES } from '../../entities/choices/status'
import { UserSelect } from './controls/UserSelect'
import { SizeSelect } from './controls/SizeSelect'
import { PRIORITIES } from '../../entities/choices/priority'
import { ModelUpdate } from '../../utils/types'
import { StatusSelect } from './controls/StatusSelect'
import { TrackerControl } from '../../tracking/TrackerControl'
import { PrioritySelect } from './controls/PrioritySelect'
import { taskDraggableId } from '../drag-n-drop'
import { ShortUser, User } from '../../entities/user/model'
import {
  AddTimeManuallyCallback,
  AddTimeManuallyObject,
  StartTrackingCallback,
} from '../../tracking/types'
import { Activity, TASK_ACTIVITIES } from '../../entities/choices/activity'
import { ID } from '@datorama/akita'
import { EpicSelect } from '../../components/controls/EpicSelect'
import { SprintSelect } from '../../components/controls/SprintSelect'
import { generateTaskDetailUrl } from '../../routes'
import { IsBlockedBrick } from '../../components/IsBlockedBrick'
import { IFormErrors } from '../state/store'
import { TaskType } from '../../entities/task-type/model'
import { TaskTypes } from '../../components/ui/task-type/TaskTypes'
import { checkIsTimeAlmostExceeded } from '../../utils/checkIsTimeAlmostExceeded'
import { SORT_STEP } from '../../utils/lists'
import { projectPageTaskContext } from '../ProjectPage'

export interface TaskListItemProps {
  task: Task
  epic?: Epic | null
  users: User[] | ShortUser[]
  sprint?: Sprint | null
  baseURL?: string
  location?: 'epic' | 'sprint'
  isLinkedTask?: boolean | false
  dragProvided?: DraggableProvided
  isHighlighted?: boolean
  onRemove?: (idToRemove: ID) => void
  onTaskUpdate: (update: ModelUpdate<Task>) => void
  onStopTracking: () => void
  onStartTracking: StartTrackingCallback
  onAddTimeManually: AddTimeManuallyCallback
  epics?: Epic[]
  sprints?: Sprint[]
  taskTypes: TaskType[]
  filteredSprints?: Sprint[]
  currentUser?: User
  addTimeFormErrors?: IFormErrors | null
  isAddTimeFormOpened?: boolean
  onOpenAddTimeModal?: () => void
  onCloseAddTimeModal?: () => void
}

function _TaskListItem(props: TaskListItemProps) {
  const {
    epic,
    task,
    users,
    location,
    isLinkedTask,
    onRemove,
    onTaskUpdate,
    onStartTracking,
    onStopTracking,
    onAddTimeManually,
    epics,
    sprints,
    taskTypes,
    sprint,
    filteredSprints,
    currentUser,
    addTimeFormErrors,
    isAddTimeFormOpened,
    onOpenAddTimeModal,
    onCloseAddTimeModal,
  } = props
  const [hover, setHover] = useState(false)

  const updateTask = useCallback(
    (update: Partial<Task>) => {
      onTaskUpdate({ id: task.id, ...update })
    },
    [onTaskUpdate, task.id]
  )

  const _onStartTracking = useCallback(
    (activity: Activity | null) => {
      onStartTracking && onStartTracking('task', +task.id, activity)
    },
    [onStartTracking, task.id]
  )

  const _onAddTimeManually = useCallback(
    (data: AddTimeManuallyObject) => {
      onAddTimeManually('task', +task.id, data)
    },
    [onAddTimeManually, task.id]
  )

  const divProps = props.dragProvided
    ? {
        ref: props.dragProvided.innerRef,
        ...props.dragProvided.draggableProps,
      }
    : {}

  const className = classNames(['task-list-item'], {
    'task-list-item_highlighted': props.isHighlighted,
  })

  const onMouseEnter = useCallback(() => setHover(true), [])
  const onMouseLeave = useCallback(() => setHover(false), [])

  const url = `${props.baseURL}/task/${props.task.code}`
  let linkedUrl = url

  if (props.baseURL) {
    linkedUrl = generateTaskDetailUrl(
      props.baseURL!.replace('/', ''),
      props.task.project,
      props.task.code
    )
  }

  const onPrioritySelect = useCallback(
    (priority) => {
      updateTask({ priority: priority })
    },
    [updateTask]
  )

  const onSizeSelect = useCallback(
    (size) => {
      updateTask({ size: size })
    },
    [updateTask]
  )

  const onUserSelect = useCallback(
    (userId) => updateTask({ assignee: userId }),
    [updateTask]
  )

  const onStatusSelect = useCallback(
    (status) => {
      updateTask({ status: status })
    },
    [updateTask]
  )

  const allTasks = useContext(projectPageTaskContext)!

  const onEpicSelect = useCallback(
    (epicId: ID) => {
      const epicOrder = (epicID: ID) => {
        const epicTasks = sortBy(
          allTasks.filter((item) => item.epic === epicID),
          'epic_order'
        )
        return epicTasks.length
          ? epicTasks.slice(-1)[0].epic_order + SORT_STEP
          : 0
      }
      updateTask({ epic: epicId, epic_order: epicOrder(epicId) })
    },
    [allTasks, updateTask]
  )

  const onSprintSelect = useCallback(
    (sprintId: ID) => {
      const sprintOrder = (sprintID: ID) => {
        const sprintTasks = sortBy(
          allTasks.filter((item) => item.sprint === sprintID),
          'sprint_order'
        )
        return sprintTasks.length
          ? sprintTasks.slice(-1)[0].sprint_order + SORT_STEP
          : 0
      }
      updateTask({ sprint: sprintId, sprint_order: sprintOrder(sprintId) })
    },
    [allTasks, updateTask]
  )

  const onRemoveTask = useCallback(() => {
    if (onRemove) {
      onRemove(task.id)
    }
  }, [onRemove, task.id])

  const currentTaskTypes = useMemo(
    () => taskTypes?.filter((taskType) => task.task_type.includes(taskType.id)),
    [task, taskTypes]
  )

  const [inViewPort, ref] = useInViewport<HTMLDivElement>()

  const isSprintClosedOrActive = useMemo(() => {
    return (
      !!sprint &&
      [SprintStatus.STARTED, SprintStatus.COMPLETE].includes(sprint.status)
    )
  }, [sprint])
  const isEstimateNeeded = useMemo(() => {
    return isSprintClosedOrActive && !task.current_estimate
  }, [isSprintClosedOrActive, task.current_estimate])

  const shouldShowTasks = inViewPort || isLinkedTask
  const disabled = isLinkedTask || task.is_readonly

  // estimated_time is more predictable, so take it when available
  const maxEstimatedTime =
    _.get(
      task,
      'estimated_time.total.max_estimated_time',
      task.max_estimated_time
    ) || null

  const isEstimatePresent = !!maxEstimatedTime

  const isEstimateTimeExceeded = maxEstimatedTime
    ? isSprintClosedOrActive && task.current_estimate! > maxEstimatedTime
    : false

  const isSpentTimeExceeded = maxEstimatedTime
    ? isSprintClosedOrActive && maxEstimatedTime < task.time_logged
    : false

  const isSpentTimeAlmostExceeded = maxEstimatedTime
    ? isSprintClosedOrActive &&
      checkIsTimeAlmostExceeded(maxEstimatedTime, task.time_logged)
    : false

  const isEnabledLinkedTask = isLinkedTask && !task.is_readonly

  return (
    <div
      className={className}
      {...divProps}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={className} ref={ref}>
        {isEnabledLinkedTask ? (
          <button
            onClick={onRemoveTask}
            className="task-list-item__remove-linked-task"
          >
            <Icon iconSize={12} color="#fff" icon={IconNames.CROSS} />
          </button>
        ) : (
          props.dragProvided && (
            <div
              className="task-list-item__handle"
              {...props.dragProvided.dragHandleProps}
            >
              <SmallDashOutlined rotate={90} />
            </div>
          )
        )}
        <div className="task-list-item__body">
          <div className="task-list-item__properties">
            {shouldShowTasks ? (
              <div className="task-list-item__properties-left">
                <div className="task-list-item__properties-select">
                  <PrioritySelect
                    disabled={disabled}
                    priority={task.priority}
                    priorities={PRIORITIES}
                    onSelect={onPrioritySelect}
                  />
                </div>
                <div className="task-list-item__size-select">
                  <SizeSelect
                    size={task.size}
                    sizes={SIZES}
                    isInTask={true}
                    disabled={disabled}
                    onSelect={onSizeSelect}
                    hasTooltip={true}
                    isEstimatePresent={isEstimatePresent}
                    isEstimateTimeExceeded={isEstimateTimeExceeded}
                    isEstimateNeeded={isEstimateNeeded}
                    isSpentTimeExceeded={isSpentTimeExceeded}
                    isSpentTimeAlmostExceeded={isSpentTimeAlmostExceeded}
                  />
                </div>

                {isLinkedTask ? (
                  <Link to={linkedUrl} className="task-list-item__code">
                    {task.code}
                  </Link>
                ) : (
                  <TaskName
                    url={url}
                    task={task}
                    location={location}
                    epics={epics}
                    onEpicSelect={onEpicSelect}
                    sprints={sprints}
                    filteredSprints={filteredSprints}
                    onSprintSelect={onSprintSelect}
                  />
                )}

                {isLinkedTask && !!epic && (
                  <>
                    <span className="task-list-item__group">{epic.name}</span>
                  </>
                )}

                <Icon
                  className="task-list-item__copy-link-icon"
                  iconSize={12}
                  icon={IconNames.LINK}
                  onClick={() => {
                    copyLinkToClipboard(window.location.origin + url, task.name)
                  }}
                />
              </div>
            ) : (
              <Skeleton
                className="task-list-item-skeleton"
                height={22}
                width={100}
              />
            )}

            <div className="task-list-item__properties-right">
              {inViewPort ? (
                <>
                  {!isLinkedTask && (
                    <span className="tracker-control">
                      <TrackerControl
                        seconds={task.time_logged}
                        trackingObject={task}
                        trackingObjectType="task"
                        activities={TASK_ACTIVITIES}
                        hideIfPossible={!hover}
                        onStop={onStopTracking}
                        onStart={_onStartTracking}
                        onAddTimeManually={_onAddTimeManually}
                        currentUser={currentUser}
                        addTimeFormErrors={addTimeFormErrors}
                        isAddTimeFormOpened={isAddTimeFormOpened}
                        onOpenAddTimeModal={onOpenAddTimeModal}
                        onCloseAddTimeModal={onCloseAddTimeModal}
                      />
                    </span>
                  )}
                </>
              ) : (
                <Skeleton
                  className="task-list-item-skeleton task-list-item-skeleton_time"
                  width={40}
                  height={22}
                />
              )}
              <div className="task-list-item__user-select">
                {shouldShowTasks ? (
                  <UserSelect
                    onlyAvatar
                    users={users}
                    userId={task.assignee}
                    disabled={disabled}
                    onSelect={onUserSelect}
                  />
                ) : (
                  <Skeleton
                    className="task-list-item-skeleton"
                    width={22}
                    height={22}
                  />
                )}
              </div>
              {shouldShowTasks ? (
                <StatusSelect
                  status={task.status}
                  statuses={STATUSES}
                  disabled={disabled}
                  onSelect={onStatusSelect}
                />
              ) : (
                <Skeleton
                  className="task-list-item-skeleton"
                  width={70}
                  height={22}
                />
              )}
            </div>
          </div>
          <div className="task-list-item__title">
            {task.is_blocked && (
              <div className="task-list-item__title-bricks">
                {task.is_blocked && (
                  <div className="task-list-item__title-blocked">
                    <IsBlockedBrick />
                  </div>
                )}
              </div>
            )}
            {!!currentTaskTypes?.length && (
              <TaskTypes types={currentTaskTypes} />
            )}
            {shouldShowTasks ? (
              <Link to={isLinkedTask ? linkedUrl : url}>{task.name}</Link>
            ) : (
              <Skeleton className="task-list-item-skeleton" height={15} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

interface TaskNameProps {
  url: string
  task: Task
  location?: 'sprint' | 'epic'
  epics?: Epic[]
  sprints?: Sprint[]
  filteredSprints?: Sprint[]
  onEpicSelect: (epicId: ID) => void
  onSprintSelect: (sprintId: ID) => void
}

function TaskName(props: TaskNameProps) {
  const {
    url,
    task,
    epics,
    sprints,
    filteredSprints,
    onEpicSelect,
    onSprintSelect,
  } = props
  const currentEpic = epics?.find((item) => task.epic === item.id)
  const currentSprint = sprints?.find((item) => task.sprint === item.id)
  const disabled = task.is_readonly

  return (
    <>
      <Link to={url} className="task-list-item__code">
        {task.code}
      </Link>

      {props.location === 'sprint' && !!epics && (
        <div className="task-list-item__group">
          <EpicSelect
            disabled={disabled}
            className={'breadcrumb-select'}
            portalClassName="breadcrumbs-portal"
            popoverClassName="breadcrumbs-popover"
            items={epics}
            currentItem={currentEpic}
            onItemSelect={(epic) => onEpicSelect(epic.id)}
            canClear={true}
          />
        </div>
      )}

      {props.location === 'epic' && !!filteredSprints && (
        <div className="task-list-item__group">
          <SprintSelect
            disabled={disabled}
            className={'breadcrumb-select'}
            portalClassName="breadcrumbs-portal"
            popoverClassName="breadcrumbs-popover"
            sprints={filteredSprints}
            selectedSprint={currentSprint}
            onSprintSelect={(sprint) => onSprintSelect(sprint.id)}
            canClear={true}
          />
        </div>
      )}
    </>
  )
}

// using here lodash and not react-fast-compare because of error causing by
// adding external link in the description of a task
// https://timer.gearheart.io/1/95/task/TM3-34697
export const TaskListItem = React.memo(_TaskListItem, isEqual)

export interface DraggableTaskListItemProps extends TaskListItemProps {
  index: number
  location: 'sprint' | 'epic'
}

function _DraggableTaskListItem(props: DraggableTaskListItemProps) {
  return (
    <Draggable
      key={props.index}
      draggableId={taskDraggableId(props.task, props.location)}
      index={props.index}
    >
      {(
        dragProvided: DraggableProvided,
        _dragSnapshot: DraggableStateSnapshot
      ) => <TaskListItem dragProvided={dragProvided} {...props} />}
    </Draggable>
  )
}

export const DraggableTaskListItem = React.memo(_DraggableTaskListItem, isEqual)

function copyLinkToClipboard(url: string, text: string) {
  try {
    const anchor = document.createElement('a')
    anchor.innerHTML = text
    anchor.href = url
    // @ts-ignore: we have obsolete ts version
    const clipboardItem = new window.ClipboardItem({
      "text/plain": new Blob(
          [anchor.innerText],
          { type: "text/plain" }
      ),
      "text/html": new Blob(
          [anchor.outerHTML],
          { type: "text/html" }
      ),
    })
    // @ts-ignore: we have obsolete ts version
    navigator.clipboard.write([clipboardItem])
    message.success('Task link copied to clipboard.')
  } catch (err) {
    message.error('Failed to copy task link to clipboard.')
    console.error(err)
  }
}
