import '../../styles/user-field.scss'

import React from 'react'
import { DateInput } from '@blueprintjs/datetime'
import { Moment } from 'moment-timezone/moment-timezone'

import { DATE_STORE_FORMAT, formatDate, parseDate } from '../../../utils/date'

interface UserProfileDateInputProps {
  value: Moment | null
  editable: boolean
  onChange: (value: string) => void
}

export function UserProfileDateInput(props: UserProfileDateInputProps) {
  if (!props.editable && !props.value) {
    return null
  }
  return (
    <DateInput
      dayPickerProps={{
        firstDayOfWeek: 1,
      }}
      disabled={!props.editable}
      value={props.value?.toDate()}
      parseDate={(value: string) => parseDate(value)}
      formatDate={(date: Date) => formatDate(date)}
      placeholder={props.editable ? 'Choose date...' : 'Not set'}
      minDate={parseDate('1940-01-01')}
      inputProps={{ leftIcon: 'calendar' }}
      popoverProps={{
        className: 'user-field user-field_date',
        position: 'bottom',
        minimal: true,
      }}
      onChange={(selectedDate: Date, isUserChange: boolean) =>
        props.onChange(formatDate(selectedDate, DATE_STORE_FORMAT))
      }
    />
  )
}
