import './styles/project-details-info-deadline.scss'

import React, { useCallback, useState } from 'react'
import { DateInput } from '@blueprintjs/datetime'

import { ProjectDetailsInfoItem } from './ProjectDetailsInfoItem'
import { DATE_STORE_FORMAT, formatDate, parseDate } from '../../../utils/date'

interface ProjectDetailsInfoDeadlineProps {
  date: Date | null
  onChange: (value: string | null) => void
}

export function ProjectDetailsInfoDeadline(
  props: ProjectDetailsInfoDeadlineProps
) {
  const { onChange } = props
  const [date, setDate] = useState(props.date || null)

  const onChangeHandler = useCallback(
    (value) => {
      const dateToStore = value
        ? formatDate(value, DATE_STORE_FORMAT).toString()
        : null

      if (date === value) return

      onChange(dateToStore)
      setDate(value)
    },
    [onChange, date]
  )

  return (
    <ProjectDetailsInfoItem
      className="project-details-info-deadline"
      title="Deadline"
    >
      <DateInput
        dayPickerProps={{
          firstDayOfWeek: 1,
        }}
        highlightCurrentDay={true}
        onChange={(newDate) => onChangeHandler(newDate)}
        value={date}
        placeholder="Select date"
        minDate={new Date(2009, 12, 31)}
        maxDate={new Date(2030, 11, 31)}
        parseDate={(value: string) => parseDate(value)}
        formatDate={(date: Date) => formatDate(date)}
        popoverProps={{
          targetTagName: 'div',
          position: 'bottom',
          minimal: true,
          modifiers: {
            offset: {
              offset: '0 10px',
            },
          },
        }}
      />
    </ProjectDetailsInfoItem>
  )
}
