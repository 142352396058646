export interface PriorityChoice {
  value: TaskPriorityType
  label: string
}

export enum PriorityColor {
  HOTFIX = '#fa8c16',
  BLOCKER = '#d4380d',
  MAJOR = 'var(--color-text-link)',
  MINOR = '#389e0d',
  TRIVIAL = 'var(--color-text-inactive)',
}

export const PRIORITIES: PriorityChoice[] = [
  {
    value: 'hotfix',
    label: 'Hotfix',
  },
  {
    value: 'blocker',
    label: 'Blocker',
  },
  {
    value: 'major',
    label: 'Major',
  },
  { value: 'minor', label: 'Minor' },
  {
    value: 'trivial',
    label: 'Trivial',
  },
]

export type TaskPriorityType =
  | 'hotfix'
  | 'blocker'
  | 'major'
  | 'minor'
  | 'trivial'

type PriorityMapping = {
  [key in TaskPriorityType]: string
}

export const getPriorityMapping: PriorityMapping = {
  hotfix: PriorityColor.HOTFIX,
  blocker: PriorityColor.BLOCKER,
  major: PriorityColor.MAJOR,
  minor: PriorityColor.MINOR,
  trivial: PriorityColor.TRIVIAL,
}
