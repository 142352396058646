import React, { useCallback } from 'react'
import { Select, IItemRendererProps } from '@blueprintjs/select'
import {
  FireOutlined,
  CoffeeOutlined,
  StopOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons'
import {
  PriorityChoice,
  PriorityColor,
  TaskPriorityType,
} from '../../../entities/choices/priority'
import classNames from 'classnames'

import '../../../assets/scss/select.scss'
import './styles/priority-select.scss'
import { useSelect } from '../../../utils/select'

const TypedPrioritySelect = Select.ofType<PriorityChoice>()

export interface PrioritySelectProps {
  priority: string
  disabled?: boolean | false
  priorities: PriorityChoice[]
  isBordered?: boolean | false
  isExtended?: boolean | false
  onSelect: (priority: string) => void
}

export function PrioritySelect(props: PrioritySelectProps) {
  const { onSelect, disabled } = props

  const [selectRef, showSelect, showSelectHandler] = useSelect<PriorityChoice>()

  const onItemSelect = useCallback(
    (Priority: PriorityChoice) => onSelect(Priority.value),
    [onSelect]
  )

  let priority_obj = props.priorities.find((s) => s.value === props.priority)

  if (!priority_obj) {
    return null
  }

  const findMatchingIcon = (priority: PriorityChoice) => {
    return priorityIcons[priority.value]
  }

  const selectIcon = findMatchingIcon(priority_obj)

  const buttonClasses = classNames(
    'priority-select__button',
    `priority-select__button_${priority_obj.value}`,
    {
      'priority-select__button_bordered': props.isBordered,
      'priority-select__button_extended': props.isExtended,
      'priority-select__button_disabled': disabled,
    }
  )

  function renderPriority(
    priority: PriorityChoice,
    renderProps: IItemRendererProps
  ) {
    const { modifiers, handleClick } = renderProps

    if (!modifiers.matchesPredicate) {
      return null
    }

    const menuItemClasses = classNames(
      'priority-popover__menu-item',
      'bp3-menu-item',
      `bp3-menu-item_${priority.value}`,
      { 'bp3-active': modifiers.active }
    )

    const menuItemIcon = findMatchingIcon(priority)

    return (
      <li
        className={menuItemClasses}
        onClick={handleClick}
        key={priority.value}
      >
        <div className="priority-popover__icon">{menuItemIcon}</div>
        <div className="bp3-text-overflow-ellipsis bp3-fill">
          {priority.label}
        </div>
      </li>
    )
  }

  const selectClasses = classNames('priority-select', 'select', {
    select_disabled: disabled,
  })

  const CurrentSelectItem = (
    <>
      {selectIcon}
      {props.isExtended && (
        <div className="priority-select__button-text">{priority_obj.label}</div>
      )}
    </>
  )

  if (!showSelect)
    return (
      <div className={selectClasses}>
        <button onClick={showSelectHandler} className={buttonClasses}>
          {CurrentSelectItem}
        </button>
      </div>
    )

  return (
    <TypedPrioritySelect
      ref={selectRef}
      items={props.priorities}
      disabled={disabled}
      activeItem={priority_obj}
      className={selectClasses}
      itemsEqual={(one, two) => one.value === two.value}
      onItemSelect={onItemSelect}
      filterable={false}
      itemRenderer={renderPriority}
      popoverProps={{
        minimal: true,
        position: 'bottom',
        popoverClassName: 'popover priority-popover',
      }}
    >
      <button className={buttonClasses}>{CurrentSelectItem}</button>
    </TypedPrioritySelect>
  )
}

const priorityIcons: Record<TaskPriorityType, React.ReactNode> = {
  hotfix: (
    <FireOutlined style={{ fontSize: '14px', color: PriorityColor.HOTFIX }} />
  ),
  blocker: (
    <StopOutlined style={{ fontSize: '14px', color: PriorityColor.BLOCKER }} />
  ),
  major: (
    <ArrowUpOutlined style={{ fontSize: '14px', color: PriorityColor.MAJOR }} />
  ),
  minor: (
    <ArrowDownOutlined
      style={{ fontSize: '14px', color: PriorityColor.MINOR }}
    />
  ),
  trivial: (
    <CoffeeOutlined
      style={{ fontSize: '14px', color: PriorityColor.TRIVIAL }}
    />
  ),
}
